<template>
  <main class="Playground">
    <TheSubHeader
      icon="bank"
      title="Playground"
      type="filter"
      organizer-gap="1rem"
      organizer-columns="auto"
    >
      <template #filter />
    </TheSubHeader>
    <div class="Playground__content">
      <div class="Playground__contentBox">
        <DataTable
          :header="table.headers"
          :table="tableContent"
          :sort-columns="sortColumns"
          @sort="sort"
          @goTo="goTo"
          @see="openToast"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { DataTable } from '@/components/molecules'
import table from './table'
import { mapActions } from 'vuex'

export default {
  name: 'Playground',
  components: {
    TheSubHeader,
    DataTable
  },
  data() {
    return {
      table,
      tableContent: [],
      dateNow: 0,
      refreshTableInterval: null,
      sortColumns: {
        date: {
          direction: 'asc'
        }
      }
    }
  },
  mounted() {
    this.formatTableWarning()
    this.refreshTableInterval = setInterval(this.refreshTable.bind(this) , 1000)
  },
  destroyed() {
    clearInterval(this.refreshTableInterval)
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading'
    }),
    refreshTable() {
      this.formatTableWarning()
    },
    formatTableWarning() {
      let nowDate = new Date()
      let yesterdayDateStart = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate() - 1, 0, 0, 0, 0)
      let todayDateStart = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 0, 0, 0, 0)
      let todayDateLimit = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), 20, 0, 0, 0)
      this.tableContent = this.table.content.map(t => {
        let cell = {
          ...t,
          date: {
            value: t.date
          }
        }
        let orderDate = new Date(t.date)
        let orderDateStart = new Date(orderDate.getFullYear(), orderDate.getMonth(), orderDate.getDate(), 0, 0, 0, 0)
        if (
          orderDateStart < yesterdayDateStart ||
          orderDateStart >= yesterdayDateStart &&
          nowDate >= todayDateLimit &&
          orderDateStart < todayDateStart
        ) {
          cell.date.features = {
            warning: {
              value: 'alert'
            }
          }
        } else if (
          orderDateStart < todayDateStart &&
          nowDate < todayDateLimit
        ) {
          cell.date.features = {
            warning: {
              value: 'notice'
            }
          }
        }
        return cell
      })
    },
    sort(e) {
      let direction = this.sortColumns[e.cell.id].direction
      this.sortColumns[e.cell.id].direction = (direction === 'asc') ? 'desc' : 'asc'
      this.search(this.sortColumns[e.cell.id].direction)
    },
    search(direction){
      this.setLoading(true)
      setTimeout(() => {
        alert(direction)
        this.table.content.reverse()
        this.setLoading(false)
      }, 1000)
    },
    goTo(e) {
      alert(e.data.content)
    },
    openToast() {
      this.$toast('Abrindo um toast de 3 segundos', {
        timeout: 3000,
        type: 'warning'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Playground {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: '. main .';
    grid-column-gap: 1rem;
  }
  &__contentBox {
    grid-area: main;
    margin-bottom: 50px;
  }
}
</style>
