/* eslint-disable */
export default {
  "headers":{
    "id":{
      "id":"id",
      "param":"id",
      "label":"ID",
      "method":false,
      "isVisible":false
    },
    "date":{
      "id":"date",
      "param":"orders.date",
      "label":"Data Ocorrência",
      "method": "millisecondsToDateHour", 
      "features": {
        "icon": {
          "value": "chevron-down",
          "position": "left",
          "sections": ["header"],
          "options": [
            {
              "event": "sort"
            }
          ]
        }
      },
      "isVisible":true,
    },
    "driver.name":{
      "id":"driver.name",
      "param":"driver.name",
      "label":"driver",
      "method":false,
      "features": {
        "icon": {
          "value": "copy",
          "sections": ["header","content"],
          "options": [
            {
              "event": "copy"
            },
            {
              "event": "hover",
              "content": "Clique aqui para copiar"
            },
          ]
        }
      },
      "isVisible":true
    },
    "tracking_code":{
      "id":"tracking_code",
      "param":"orders.tracking_code",
      "label":"Cód. Rastreio",
      "method":false,
      "features": {
        "icon": {
          "value": "copy",
          "sections": ["header","content"],
          "options": [
            {
              "event": "copy"
            },
            {
              "event": "hover",
              "content": "Clique aqui para copiar"
            },
          ]
        }
      },
      "isVisible":true
    },
    "addresses.destiny_zip":{
      "id":"addresses.destiny_zip",
      "param":"addresses.destiny_zip",
      "label":"CEP",
      "method": "cep",
      "features": {
        "icon": {
          "value": "copy",
          "sections": ["content"],
          "options": [
            {
              "event": "copy"
            },
            {
              "event": "hover",
              "content": "Clique aqui para copiar"
            }
          ]
        }
      },
      "isVisible":true,
    }, 
    "status.name":{
      "id":"status.name",
      "param":"status.name",
      "label":"Motivo",
      "method":false,
      "isVisible":true
    },
    "actions":{
      "id":"actions",
      "param":"actions",
      "label":"Actions",
      "method":false,
      "actions":true,
      "isVisible":true
    }
  },
  "content":[
    {
      "id":2,
      "date": 1655048002000,
      "driver.name": {
        "value": "Fulano de Tal",
        "features": {
          "icon": {
            "value": "package",
            "position": "left"
          },
          "theme": {
            "value": "dark",
            "options": [
              {
                "event": "hover",
                "content": "Pedido pesado"
              }
            ]
          }
        },
      },
      "tracking_code": {
        "value": "2",
        "features": {
          "warning": {
            "value": "primary"
          }
        }
      },
      "addresses.destiny_zip": null,
      "status.name": {
        "value": "Pendente",
        "features": {
          "icon": {
            "value": "comment",
            "options": [
              {
                "event": "hover",
                "content": "<strong>comentário:</strong><br> Dei campainha 5x e nada! Nenhum movimento."
              }
            ]
          }
        }
      },
      "actions":[
        {
          "action_id":"visualize",
          "has_permission":true
        }
      ]
    },
    {
      "id":3,
      "date": 1655332916000,
      "driver.name": '',
      "tracking_code": {
        "value": "3",
        "features": {
          "warning": {
            "value": "accent"
          }
        }
      },
      "addresses.destiny_zip": "04018030",
      "status.name": "Pendente",
      "actions":[
        {
          "action_id":"visualize",
          "has_permission":true
        }
      ]
    },
    {
      "id":1,
      "date": 1655401377000,
      "driver.name": {
        "value": "Fulano de Tal",
        "features": {
          "theme": {
            "value": "dark"
          }
        },
      },
      "tracking_code": {
        "value": "1",
        "features": {
          "warning": {
            "value": "informative"
          }
        }
      },
      "addresses.destiny_zip": "04018030",
      "status.name": {
        "value": "Pendente",
        "features": {
          "goTo": {
            "value": "example",
            "options": [
              {
                "event": "goTo",
                "content": "example"
              }
            ]
          }
        }
      },
      "actions":[
        {
          "action_id":"visualize",
          "has_permission":true
        }
      ]
    },
  ]
}