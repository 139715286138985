<template>
  <main class="PackagesIncoming">
    <TheSubHeader
      icon="dashboard-package-request"
      title="Recebimento de Pacotes"
      page-title-tag="Packages Incoming"
      type="filter"
      :actions="[userType === 'motorista_dropoff' ? {} : 'back']"
      organizer-gap="1rem"
      organizer-columns="1fr 1fr 2fr 200px"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.dropoffsExport && permissions.dropoffsExport.isVisible"
          icon="export-alt"
          color="dark"
          label="Exportar Dropoff"
          :track="trackSubheaderButtonString('Packages Incoming', 'Export Dropoff')"
          @click="goTo('/dropoff/export')"
        />
        <BaseButton
          v-if="permissions && permissions.packagesHistory && permissions.packagesHistory.isVisible"
          icon="package-request"
          color="dark"
          label="Histórico de recebimento"
          :track="trackSubheaderButtonString('Packages Incoming', 'Packages History')"
          @click="goTo('/packages/history')"
        />
        <BaseButton
          v-if="permissions && permissions.packagesExport && permissions.packagesExport.isVisible"
          icon="export-alt"
          color="dark"
          label="Exportar Pacotes"
          :track="trackSubheaderButtonString('Packages Incoming', 'Export Packages')"
          @click="goTo('/packages/export')"
        />
      </template>
      <template #filter>
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.driver"
          name="Motorista"
          label="Motorista"
          placeholder="Selecione"
          :read-only="disableFields.driver"
          suggestions="searchDrivers"
          @input="filterDriver"
        />
        <!-- Stores -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.store_id"
          name="Lojista"
          label="Lojista"
          multiple
          placeholder="Selecione"
          :read-only="disableFields.store_id"
          :select-options="null"
          :fetch-options="'stores/fetchStoresImportAsync'"
          @input="filterStores($event), updateTags"
        />
        <!-- Pedidos -->
        <BaseTreeSelect
          ref="query"
          v-model="query"
          name="Pesquisar"
          label="Pesquisar"
          placeholder="Pesquisar código do pacote"
          :read-only="disableFields.query"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :enable-bipping="true"
          :tooltip="tooltip"
          :clearable="false"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @select="callbackInputBarcodeScanned"
          @deselect="callbackInputBarcodeScanned"
          @clear="callbackInputBarcodeScanned"
        />
        <!-- Search -->
        <BaseButton
          label="Pesquisar"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          :disabled="disableFields.search"
          @click="resetSearch()"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <div class="PackagesIncoming__tags">
          <FiltersTag :tags="allTags" @removeTag="confirmRemoveTag" @clear="clearAllTags" />
        </div>
      </template>
    </TheSubHeader>

    <!-- Table -->
    <div class="PackagesIncoming__content">
      <div :class="[filters.query.length > 0 ?'PackagesIncoming__table' : 'PackagesIncoming__noContent']">
        <DataTable
          v-if="filters.query.length > 0"
          :header="incomingPackagesWithDeleteAction.data.headers"
          :table="incomingPackagesWithDeleteAction.data.content"
          no-content-text="Nenhum pacote encontrado"
          is-sticky
          @remove="confirmRemoveItemFromTable"
        />
        <p v-else>
          Insira itens na pesquisa para exibir os pacotes que estão aguardando o
          recebimento. Você pode inserir manualmente ou utilizar um leitor de
          código de barras.
        </p>
        <div
          v-if="filters.query.length > 0"
          class="PackagesIncoming__confirm"
        >
          <div class="PackagesIncoming__confirm__Content">
            <div
              v-if="incomingPackages.data.content.length > 0 && filters.query.length > 0"
              class="PackagesIncoming__confirm--text"
              v-html="incomingPackagesSelectedText"
            />
            <div v-if="totalPackagesToReceive > 0" class="PackagesIncoming__confirm--total">
              <span>Total de {{ incomingPackages.data.content.length }}/{{ totalPackagesToReceive }}</span>
            </div>
          </div>
          <BaseButton
            v-if="incomingPackages.data.content.length > 0"
            class="PackagesIncoming__confirm--button"
            label="Confirmar recebimento"
            color="primary-base"
            :disabled="incomingPackages.data.content.length === 0"
            filled
            text-color="white"
            @click="confirmSubmitIncomingOrders"
          />
        </div>
      </div>
    </div>

    <!-- Dialog - Confirm Submit -->
    <Dialog
      v-if="confirmSubmitIncomingOrdersModal"
      class="PackagesIncoming__dialog"
      :warning="confirmSubmitIncomingOrdersText"
      icon-cancel=""
      label-cancel="Voltar"
      label-confirm="Confirmar"
      :listener-keyup="false"
      :disabled-confirm="disableFields.confirmIncomingPackages"
      @cancel="cancelSubmitModal"
      @confirm="submitIncomingOrders"
    >
      <div v-if="selectedAllPackagesByDriver && cachedPackages.data.content.length > 0" class="PackagesIncoming__PackagesByDriver">
        <BaseTextarea
          v-model="packagesByDriverReason"
          label="Descreva o motivo pelo qual tem divergência no número de pacotes bipados/total."
          placeholder="Digite o motivo..."
          :max-height="120"
        />
      </div>
    </Dialog>

    <!-- Dialog - Confirm Remove -->
    <Dialog
      v-if="confirmRemoveItemFromTableModal"
      icon="report"
      class="PackagesIncoming__dialog"
      :warning="`Tem certeza que deseja remover o pacote ${itemToRemove} da confirmação de recebimento?`"
      label-cancel="Não"
      label-confirm="Sim"
      :listener-keyup="false"
      @cancel="confirmRemoveItemFromTableModal = false"
      @confirm="removeItemFromTable"
    />

    <!-- Modal - Confirm packages -->
    <Modal
      v-if="packagesConfirmationModal"
      class="PackagesIncoming__Result"
      @close="closePackagesConfirmationModal"
    >
      <template #header>
        <div class="Modal__header">
          Pacotes confirmados
        </div>
      </template>

      <template #body>
        <p class="Modal__disclaimer">
          Pacote recebido com sucesso! <br>
          Para fazer download do romaneio acesse o histórico de pacotes.
        </p>

        <div class="Modal__button">
          <BaseButton
            label="Histórico de Pacotes"
            color="dark"
            filled
            text-color="white"
            @click="goTo('/packages/history')"
          />

          <BaseButton
            label="Confirmar"
            theme="icon-right"
            color="dark"
            filled
            icon="check"
            text-color="white"
            @click="closePackagesConfirmationModal"
          />
        </div>
      </template>
    </Modal>

    <!-- Missing Packages Driver -->
    <Modal v-if="showModalMissing" class="PackagesIncoming__ModalMissing" @close="setShowModalMissing(false)">
      <template #header>
        <div class="Header">
          <span>Confirmação de Recebimento</span>
        </div>
      </template>
      <template #body>
        <div class="Body">
          <span class="question">Faltaram alguns pacotes que foram bipados pelo motorista <b>{{ modalMissing.driver }}</b>, voltar para bipagem ou deseja avançar?</span>
          <h4>Pacotes faltantes:</h4>
          <DataTable
            :header="modalMissing.data.headers"
            :table="modalMissing.data.content"
          />
          <div class="Actions">
            <BaseButton
              label="Cancelar"
              filled
              color="white"
              text-color="black"
              @click="setShowModalMissing(false)"
            />

            <BaseButton
              label="Avançar"
              color="primary-base"
              text-color="white"
              filled
              @click="setShowModalMissing(false), confirmSubmitIncomingOrdersModal = true"
            />
          </div>
        </div>
      </template>
    </Modal>

    <!-- Dialog - Missing Packages -->
    <Dialog
      v-if="showMissingPackages"
      class="PackagesIncoming__dialog"
      :warning="missingPackagesText"
      :label-cancel="false"
      label-confirm="Confirmar"
      @confirm="removeMissingPackages"
    >
      <BaseList :list="missingPackages" :title="'Nome do Pedido'" :scrollbar="true" scrollbar-height="25vh" />
    </Dialog>

    <!-- Dialog - Recover Packages -->
    <Dialog
      v-if="removeTagConfirm"
      class="PackagesIncoming__dialog PackagesIncoming__dialog--recover"
      warning="Tem certeza que deseja remover esse item dos filtros?"
      label-cancel="Não"
      label-confirm="Sim"
      @cancel="removeTagConfirm = false"
      @confirm="removeTag(removeTagTemp.name, removeTagTemp.id)"
    />

    <!-- Dialog - Recover Packages -->
    <Dialog
      v-if="packagesRecoverConfirm"
      class="PackagesIncoming__dialog PackagesIncoming__dialog--recover"
      :warning="localBeepedDialogMessages.recover"
      label-cancel="Não"
      label-confirm="Sim"
      @cancel="removeLocalBeepedPackages()"
      @confirm="recoverLocalBeepedPackages()"
    />

    <!-- Dialog - Confirm Save Packages on Exit -->
    <Dialog
      v-if="packagesRecoverExitConfirm"
      class="PackagesIncoming__dialog PackagesIncoming__dialog--recover"
      :warning="localBeepedDialogMessages.exit"
      label-cancel="Não"
      label-confirm="Sim"
      @cancel="packagesRecoverExitConfirm = false"
      @confirm="removeLocalBeepedPackages(), $router.push(route.to.path)"
    />
  </main>
</template>

<script>
import {TheSubHeader} from '@/components/organisms'
import {BaseButton, BaseTextarea, BaseTreeSelect } from '@/components/atoms'
import {DataTable, Dialog, Modal, BaseList, FiltersTag } from '@/components/molecules'
import {mapActions, mapState, mapGetters} from 'vuex'
import {packages} from '@/api'
import { BarCodeScanner } from '@/plugins/barCodeScanner/index'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'
import Mask from '@/plugins/mask/Mask.js'
import * as Sentry from '@sentry/vue' 

export default {
  name: 'PackagesIncoming',
  components: {
    TheSubHeader,
    BaseList,
    BaseTreeSelect,
    BaseButton,
    BaseTextarea,
    DataTable,
    Dialog,
    Modal,
    FiltersTag
  },
  mixins: [barCodeScannerMixin, beepingSoundMixin],
  beforeRouteLeave (to, from, next) {
    this.route = { to, from }
    let blockLeave = this.filters.query.length > 0 && !this.packagesRecoverExitConfirm
    this.packagesRecoverExitConfirm = blockLeave
    next(!blockLeave)
  },
  data() {
    return {
      //Filters
      filters: {
        driver: null,
        store_id: [],
        query: [],
      },

      //Tags
      allTags: [],

      //Dialog - Remove Tag
      removeTagConfirm: false,
      removeTagTemp: {},

      //Dialog - Confirm Submit
      confirmSubmitIncomingOrdersModal: false,
      confirmSubmitIncomingOrdersText: '',
      packagesByDriverReason: '',

      //Modal - Confirm packages
      packagesConfirmationModal: false,

      //Dialog - Missing Packages
      showMissingPackages: false,
      missingPackages: [],
      missingPackagesText: '',

      //Others
      codesToSend: [],

      // Recover Packages
      packagesRecoverConfirm: false,
      packagesRecoverExitConfirm: false,

      //
      confirmRemoveItemFromTableModal: false,
      itemToRemove: null,

      route: {
        to: null,
        from: null
      },
    }
  },
  computed: {
    ...mapState({
      incomingPackages: (state) => state.packages.incomingPackages,
      cachedPackages: (state) => state.packages.cachedPackages,
      permissions: state => state.auth.permissions,
      modalMissing: (state) => state.packages.modalMissingPackages,
      showModalMissing: (state) => state.packages.showModalMissingPackages,
    }),

    ...mapGetters({
      incomingPackagesWithDeleteAction: 'packages/incomingPackagesWithDeleteAction',
    }),

    query: {
      get() {
        return this.filters.query
      },
      set(value) {
        this.filters.query = this.removeDuplicatedPackages(value)
      }
    },

    userType() {
      return JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
    },

    //Disable
    disableFields() {
      return {
        driver: this.filters.store_id?.length > 0,
        store_id: !!this.filters.driver,
        query: !this.filters.driver && this.filters.store_id.length === 0,
        search:
          this.filters.query.length === 0 &&
          (!this.filters.driver || !this.filters.store_id),
        confirmIncomingPackages:
          ((this.filters.driver !== null && this.selectedAllPackagesByDriver) && this.cachedPackages.data.content.length > 0 ? this.packagesByDriverReason === '' : false)

      }
    },

    incomingPackagesSelectedText() {
      let total = this.incomingPackages.data.content.length
      let text = `${total} pacotes foram adicionados para a confirmação, clique no botão ${window.innerWidth <= 960 ? 'abaixo' : 'ao lado'} para confirmar o recebimento.`
      return text
    },

    selectedAllPackagesByDriver() {
      return this.incomingPackages.data.content.length !== this.totalPackagesToReceive && this.filters?.driver?.id
    },

    localBeepedDialogMessages() {
      let packagesIncoming = localStorage.getItem('packagesIncoming')
      let totalPackages = this.filters.query.length
      let totalPackagesToRecover = packagesIncoming ? this.removeDuplicatedPackages(JSON.parse(packagesIncoming)?.filters?.query)?.length : null
      let dateToRecover = packagesIncoming ? Mask.millisecondsToHour(JSON.parse(packagesIncoming)?.timestamp) : null
      return {
        recover: `Atenção! Você tem uma coleta de ${totalPackagesToRecover} pacotes não finalizada às ${dateToRecover}, deseja continuar o recebimento?`,
        exit: `Atenção! Voce está com uma coleta de ${totalPackages} pacotes em aberto, deseja realmente sair?`
      }
    },

    totalPackagesToReceive() {
      return [
        ...this.cachedPackages.data.content,
        ...this.incomingPackages.data.content
      ].filter(
        (original, i, a) => a.findIndex(filtered => (filtered.id === original.id)) === i
      ).length
    }
  },
  created() {
    window.addEventListener('beforeunload', this.beforeUnload)
  },
  mounted() {
    this.setBarCodeRules({field: 'motorista ou lojista', settingsBeeping: false})
    this.checksIfUserWantsToRecoverPackages()
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload)
    this.resetIncomingPackages()
    this.resetCachedPackages()
  },
  methods: {
    beforeUnload(e) {
      if (this.filters.query.length > 0) {
        e.preventDefault()
        e.returnValue = ''
      }
    },

    ...mapActions({
      //Filters
      fetchCachedPackagesByDriver: 'packages/fetchCachedPackagesByDriver',
      resetCachedPackages: 'packages/resetCachedPackages',
      addCachedPackages: 'packages/addCachedPackages',
      addIncomingPackages: 'packages/addIncomingPackages',
      removeIncomingPackages: 'packages/removeIncomingPackages',
      removeIncomingPackagesWithStores: 'packages/removeIncomingPackagesWithStores',
      resetIncomingPackages: 'packages/resetIncomingPackages',
      //Table
      fetchIncomingPackages: 'packages/fetchIncomingPackages',
      //Errors
      setError: 'error/setError',
      setErrorAdditional: 'error/setErrorAdditional',
      //Helpers
      setLoading: 'loading/setLoading',
      setModalMissingDriver: 'packages/setModalMissingDriver',
      fetchModalMissingPackages: 'packages/fetchModalMissingPackages',
      setShowModalMissing: 'packages/showModalMissing',
      clearModalMissing: 'packages/clearModalMissingData',
    }),

    //[INFO] - Barcode Settings Methods are insade mixin (barCodeScannerMixin)
    ...BarCodeScanner({
      onCodeDefault: 'onCodeDefault',
      onCodeSliced: 'onCodeSliced',
      onCodeBipping: 'onCodeBipping',
      callbackBarcodeScanned: 'callbackBarcodeScanned',
      callbackInputBarcodeScanned: 'callbackInputBarcodeScanned',
    }),

    //Filters
    filterDriver(data) {
      if(!data) {
        this.clearAllTags()
      }
      this.filterBarcodeSettings(data, this.callbackBarcodeScanned)
      this.checkPackagesByDriver()
    },

    filterStores(data){
      if(!data || data.length === 0) {
        this.clearAllTags()
      }
      this.filterBarcodeSettings(data, this.callbackBarcodeScanned)
      this.removeIncomingPackagesWithStores(data.map(d => d.name))
    },

    async checkPackagesByDriver() {
      if (this.filters.driver !== null) {
        const id = this.filters.driver ? this.filters.driver.id : null
        await this.fetchCachedPackagesByDriver(id)
      } else {
        this.resetCachedPackages()
      }
    },

    //Tags
    updateTags() {
      this.$nextTick(() => {
        this.allTags = [
          ...this.filters.store_id
        ]
      })
    },

    confirmRemoveItemFromTable(data) {
      this.confirmRemoveItemFromTableModal = true
      this.itemToRemove = data.code
    },

    removeItemFromTable() {
      this.removeTag(this.itemToRemove, this.itemToRemove)
      this.confirmRemoveItemFromTableModal = false
    },

    confirmRemoveTag(name, id) {
      this.removeTagTemp = { name: name, id: id }
      this.removeTagConfirm = true
    },

    removeTag(name, id) {
      this.removeTagConfirm = false
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.store_id = this.filters.store_id.filter(
        (tag) => tag.name.toLowerCase() !== name.toLowerCase()
      )
      this.filters.query = this.filters.query.filter(
        (tag) => tag.name.toLowerCase() !== name.toLowerCase()
      )
      this.removeIncomingPackages(this.filters.query)
      this.resetSearch()
    },

    clearAllTags() {
      this.allTags = []
      this.filters.driver = null
      this.filters.store_id = []
      this.filters.query = []
      this.removeIncomingPackages(this.filters.query)
      this.resetSearch()
      this.setBarcodeModel(null)
      this.resetCachedPackages()
    },

    cancelSubmitModal() {
      this.confirmSubmitIncomingOrdersModal = false
      if (this.filters?.driver?.id && this.cachedPackages.data.content.length > 0 && this.selectedAllPackagesByDriver) {
        this.setShowModalMissing(true)
      }
    },

    //Search
    async search() {
      this.checkCodesToSend()
      this.removeIncomingPackages(this.filters.query)

      if (this.codesToSend.length === 0) return
      
      let validLocalPackages = this.cachedPackages.data.content.filter(el => {
        let c = this.codesToSend.map(code => code.name.toLowerCase())
        return c.includes(el.code.toLowerCase())
      })
      if (this.cachedPackages.data.content.length > 0 && validLocalPackages.length > 0) {
        this.addIncomingPackages({
          data: {
            headers: this.cachedPackages.data.headers,
            content: validLocalPackages
          }
        })
        this.codesToSend = this.codesToSend.filter(
          c => validLocalPackages.map(
            v => v.code.toLowerCase()
          ).includes(!c.name.toLowerCase()))
        this.barcodeBeepingCheck(true)
        if (this.codesToSend.length === 0) return
      }

      let params = {}   
      params.codes = this.codesToSend.map(el => el.id)
      params.type = this.filters?.driver ? 'driver' : 'store'
      if (params.type === 'driver') {
        params.driver_id = this.filters?.driver?.id
      } else {
        params.stores = this.filters?.store_id?.map(s => s.id)
      }

      this.fetchIncomingPackages(params).then(async (res) => {
        if (res.data) {
          if (res?.packages?.length > 0) this.addCachedPackages(res)
          await this.checkIfPackagesExist()
          this.setLocalBeepedPackages()
        } else {
          this.removeMissingPackages()
        }
      })
    },

    // Local Beeped Packages
    setLocalBeepedPackages() {
      if (this.filters.query.length > 0) {
        let packagesIncoming = {
          timestamp: new Date().getTime(),
          filters: this.filters
        }
        localStorage.setItem('packagesIncoming', JSON.stringify(packagesIncoming))
      } else {
        this.removeLocalBeepedPackages()
      }
    },

    removeLocalBeepedPackages() {
      this.packagesRecoverConfirm = false
      localStorage.removeItem('packagesIncoming')
    },

    checksIfUserWantsToRecoverPackages() {
      if (localStorage.getItem('packagesIncoming')) {
        const packagesIncoming = JSON.parse(localStorage.getItem('packagesIncoming'))
        const nowDate = new Date().getTime()
        const timeIntervalToRecover = 30 /* min */ * 60 /* seconds */ * 1000 /* miliseconds */ // 30 minutos
        if (nowDate - packagesIncoming.timestamp < timeIntervalToRecover) {
          this.packagesRecoverConfirm = true
        } else {
          this.removeLocalBeepedPackages()
        }
      }
    },

    recoverLocalBeepedPackages() {
      this.packagesRecoverConfirm = false
      const packagesIncoming = JSON.parse(localStorage.getItem('packagesIncoming'))
      this.filters = {
        ...packagesIncoming.filters,
        query: this.removeDuplicatedPackages(packagesIncoming.filters.query)
      }
      packagesIncoming.filters.query.map((tag) => {
        this.$refs.query.addTag(tag.name)
      })
      this.search()
    },

    removeDuplicatedPackages(packages) {
      return packages.filter((value, index, self) =>
        index === self.findIndex((t) => (
          String(t.id).toLowerCase() === String(value.id).toLowerCase()
        ))
      )
    },

    checkIfPackagesExist() {
      let query = [...new Set(this.filters.query.map((q) => q.name))]
      let packagesExist = this.incomingPackages.data.content.map((_package) =>
        _package.code != null ? _package.code.toLowerCase() : _package.code
      )

      this.missingPackages = query.filter(
        (f) => !packagesExist.includes(f.toLowerCase())
      )
      if (this.missingPackages.length > 0) {
        if (this.missingPackages.length > 1) {
          this.missingPackagesText = 'Alguns pacotes de sua pesquisa não foram encontrados ou já foram recebidos no sistema com base nos filtros que você escolheu:'
          this.showMissingPackages = true
        } else {          
          this.$toast.error(`O código "${this.missingPackages[0]}" não foi encontrado no sistema ou já foi recebido com base nos filtros que você escolheu`, {
            toastClassName: 'big'
          })
          this.removeMissingPackages()
        }
        this.barcodeBeepingCheck(false)
      } else if (packagesExist.length > 0) {
        this.barcodeBeepingCheck(true)
      }
    },

    //Checkers
    checkCodesToSend() {
      let packagesOnFilter = this.filters.query
      let packagesOnTable = this.incomingPackages.data.content.map((_package) =>
        _package.code != null ? _package.code.toLowerCase() : _package.code
      )
      this.codesToSend = packagesOnFilter.filter(
        (f) => !packagesOnTable.includes(f.name.toLowerCase())
      )
    },

    //Reset
    resetSearch() {    
      this.setLocalBeepedPackages()
      this.search()
    },

    async checkMissingPackages() {
      this.setModalMissingDriver(this.filters.driver.name)

      return await this.fetchModalMissingPackages({
        driver_id: this.filters.driver.id,
        packages: this.incomingPackages.data.content.map(({ id }) => id)
      })
    },

    //Table
    async confirmSubmitIncomingOrders() {
      if(this.filters?.driver?.id) {
        if (this.selectedAllPackagesByDriver) {
          await this.checkMissingPackages()
        }

        if(this.showModalMissing) return
      }

      let s = this.incomingPackages.data.content.map((p) => p.id).length > 1 ? 's' : ''
      this.confirmSubmitIncomingOrdersText = `Tem certeza que deseja confirmar o recebimento de ${this.incomingPackages.data.content.map((p) => p.id).length} pacote${s}?`
      this.confirmSubmitIncomingOrdersModal = true
    },

    //Dialog - Confirm Submit
    cancelIncomingOrders() {
      this.confirmSubmitIncomingOrdersModal = false
      this.packagesByDriverReason = ''
    },

    async submitIncomingOrders() {
      await this.setSendIncomingPackages()
    },

    async setSendIncomingPackages() {
      this.confirmSubmitIncomingOrdersModal = false

      const obj = {
        type: this.filters.driver ? 'driver' : 'store',
        driver_id: this.filters.driver ? this.filters.driver.id : null,
        store_id: this.filters.store_id.length > 0 ? this.filters.store_id[0].id : null,
        reason: this.filters.driver ? this.packagesByDriverReason : null,
        total: this.cachedPackages.data.content.length,
        packages: this.incomingPackages.data.content.map((p) => p.id)
      }

      this.setLoading(true)
      await packages.sendIncomingPackages(
        ({...obj}),
        (res) => {
          this.closePackagesConfirmationModal()
          if (!res.is_dropoff) {
            this.packagesConfirmationModal = true
          }
        },
        (e) => {
          if (e.validation) {
            const error = new Error('Erro no recebimento de pacotes', { cause: e })
            Sentry.captureException(error, scope => {
              scope.setExtra('request', JSON.stringify(obj))
              scope.setExtra('response', JSON.stringify(e))
            })
            this.setError('Ops... Ocorreu algum erro. Por favor<br> recarregue a página ou tente novamente.')
            this.setErrorAdditional(`<pre>${JSON.stringify(e, null, 2)}</pre>`)
          }
        },
        () => this.setLoading(false))

      this.clearModalMissing()
    },

    //Modal - Confirm packages
    closePackagesConfirmationModal() {
      this.packagesConfirmationModal = false
      this.resetCachedPackages()
      this.clearAllTags()
    },

    //Dialog - Missing Packages
    removeMissingPackages() {
      this.query.forEach(item => {
        if (!this.incomingPackages.data.content.map(i => i.code.toLowerCase()).includes(item.name.toLowerCase())) {
          this.removeTag(item.name, item.name)
          this.$refs.query.removeTag(item.name)
        }
      })
      this.showMissingPackages = false
    },

    //Helpers
    goTo(route) {
      this.$router.push(route)
    }
  },
}
</script>

<style lang="scss" scoped>
/deep/.PackagesIncoming {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: '. main .';
    grid-column-gap: 1rem;
  }

  &__table {
    grid-area: main;
    margin-bottom: 50px;
    position: relative;
  }

  &__confirm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    position: sticky;
    bottom: 0;
    padding: 15px 0;
    background: white;
    z-index: $z-active;
    border-top: 1px solid $color-neutral-soft;
    margin-top: 20px;

    @media #{$mobile-view} {
      flex-direction: column;
      width: 100%;
      z-index: 4001;
    }

    &__Content {
      display: flex;
      justify-content: space-between;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 1rem;
    }

    &--text {
      width: 100%;
      font-size: $font-size-3xsmall;
    }

    &--total {
      width: 100%;
      max-width: 240px;
      text-align: end;
    }

    &--button {
      align-self: flex-end;
      max-width: 240px;
      @media #{$mobile-view} {
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }

  &__noContent {
    grid-area: main;
    margin-bottom: 50px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      margin: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }

  &__PackagesByDriver {
    margin: 10px 20px;
  }
}
</style>
<style lang="scss">
.PackagesIncoming {
  &__dialog {
    .Dialog__Texts {
      width: 100%;
      margin-top: 0;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
  }

  .Modal__container {
    width: 550px;
    height: 200px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .Modal__header {
      padding: 5px;
    }

    .Modal__disclaimer {
      padding: 15px 45px;
      text-align: center
    }

    .Modal__button {
      justify-content: center;
      display: flex;
      justify-content: space-evenly;
      padding: 10px 0;

      .BaseButton {
        width: 180px;

        &__Text {
          margin-left: 0;
        }
      }
    }

    .Modal__body {
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
      @media screen and (max-width: 768px) {
        flex-direction: row;
      }
    }

    .table {
      padding: 0.4rem 0.8rem;
      font-size: $font-size-mini;
      color: $color-neutral-stronger;
      display: grid;
      grid-column-gap: 1rem;
      grid-template-columns: 3.5fr 3fr 3fr 3fr;
      grid-template-columns: repeat( auto-fit, minmax(50px, 3fr));
      justify-items: center;
      @media screen and (max-width: 768px) {
        width: 50%;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
        padding: 0.4rem 0;
      }
    }

    .table-item {
      @media screen and (max-width: 768px) {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
        height: 20px;
        width: 100%;
      }
    }

    .Modal__table-title {
      font-weight: $font-weight-bold;
      @media screen and (max-width: 768px) {
        padding-left: 2.5rem;
      }
    }

    .Modal__table-content {
      background: $gradient-primary;
      @media screen and (max-width: 768px) {
        background: unset;
        padding-right: 2.5rem;
        text-align: center;
      }
    }

    .Modal__download-icon {
      height: 1.2rem;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        margin-top: -5px;
      }
    }
  }

   &__ModalMissing {
    .Modal__container {
      width: calc(100% - 30px);
      height: auto;
      max-width: 900px;
      max-height: calc(100vh - 30px);
    }

    .Header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;

      .question {
        font-size: 14px;
      }

      h4 {
        margin: 20px 0 11px 0;
      }

      .Actions {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        margin-top: 2.5rem;

        button {
          width: 144px;
          height: 38px;
        }
      }
    }
  }
}
</style>
